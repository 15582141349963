import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Hero = ({ children, animated }) => {
  const className = classNames({
    hero: true,
    '--animated': animated
  });

  return (
    <div className={className}>
      <div className="hero__container">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-7 hero__content">{children}</div>
          </div>
        </div>
        <div className="hero__image">
          {/* eslint-disable-next-line global-require */}
          <i
            style={{ marginTop: '10vh', backgroundImage: `url(${require('./assets/hero.png')})` }}
          />
        </div>
      </div>
    </div>
  );
};
Hero.defaultProps = {
  children: null,
  animated: true
};
Hero.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  animated: PropTypes.bool
};
export default Hero;
