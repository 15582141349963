import React from 'react';
// import PropTypes from 'prop-types';

import Article from '../Article';
import Button from '../Button';

const classNames = require('classnames');

const WantToLearnMore = () => {
  const className = classNames({
    'learn-more': true
  });

  return (
    <div className={className}>
      <div className="learn-more__content">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
              <Article center>
                <header className="article__header">
                  <h2>Want to get started?</h2>
                </header>
                <section className="article__section">
                  <p>Register your Linguaskill test now. It’s quick and easy!</p>
                </section>
                <footer className="article__footer">
                  <Button as="link" color="white" onClick={() => '/register'}>
                    Learn More
                  </Button>
                </footer>
              </Article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
WantToLearnMore.defaultProps = {};
WantToLearnMore.propTypes = {};
export default WantToLearnMore;
