/* global window document */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const classNames = require('classnames');

class FeaturedContentTwo extends React.Component {
  constructor() {
    super();
    this.state = {
      scroll: 0
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      let offset = 0;

      if (this.ref.current) {
        // eslint-disable-next-line react/no-find-dom-node
        offset = this.offset(ReactDOM.findDOMNode(this.ref.current)).top - st;
      }

      this.setState({ scroll: (offset / 100) * 10 });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  offset(el) {
    const rect = el.getBoundingClientRect();

    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  render() {
    const { children, left, right, color, column, image, link } = this.props;
    const { scroll } = this.state;
    const className = classNames({
      'featured-content-two': true,
      '--left': left,
      '--right': right,
      '--red': color === 'red',
      '--black': color === 'black'
    });

    return (
      <div className={className} ref={this.ref}>
        <a href={link}>
          <div className="featured-content-two__image">
            <i>
              <i
                style={{
                  backgroundImage: `url(${image})`,
                  top: `${scroll}px`
                }}
              />
            </i>
          </div>
        </a>
        <div className="featured-content-two__content">
          <div className="container">
            <div className="row">
              <div
                className={
                  column || `col-xs-12 col-sm-6${right ? ' col-xs-offset-0 col-sm-offset-6' : ''}`
                }
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FeaturedContentTwo.defaultProps = {
  left: null,
  right: null,
  children: null,
  color: null,
  image: '',
  column: null,
  link: ''
};
FeaturedContentTwo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  left: PropTypes.bool,
  right: PropTypes.bool,
  color: PropTypes.string,
  image: PropTypes.string,
  column: PropTypes.string,
  link: PropTypes.string
};
export default FeaturedContentTwo;
