/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Article from '../Article';

const classNames = require('classnames');

const Information = ({ children }) => {
  const className = classNames({
    information: true
  });

  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Article>
              <header className="article__header">
                <h2>Information about the Linguaskill test</h2>
              </header>
              <section className="article__section">
                <p>
                  Learn more about the test formats and task types, how the test is marked and how
                  results are presented. Reports on trials conducted in different countries
                  demonstrating the reliability and accuracy of the test, and the artificial
                  intelligence technology features, are also available.
                </p>
                <a className="button --red --basic" href="/exams">
                  Learn more
                </a>
              </section>
              {/* <footer className="article__footer">
                <a className="button --red --basic" href="/" target="_blank">
                  Learn more
                </a>
              </footer> */}
            </Article>
          </div>
          <div className="col-xs-12 col-sm-6">
            <img
              // eslint-disable-next-line global-require
              src={require('./linguaskill.png')}
              alt="Linguaskill"
              style={{ maxHeight: '100%', width: '100%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
Information.defaultProps = {
  children: null
};
Information.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
export default Information;
