import React from 'react';
// import PropTypes from 'prop-types';

import Article from '../Article';

const classNames = require('classnames');

const TakeALook = () => {
  const className = classNames({
    'take-a-look': true
  });

  return (
    <div className={className}>
      <div className="take-a-look__image">
        {/* eslint-disable-next-line global-require */}
        <i style={{ backgroundImage: `url(${require('./photo1.jpg')})` }} />
      </div>
      <div className="take-a-look__content">
        <div className="container">
          <div className="row">
            <div className="col-xs-8 col-xs-offset-4 col-sm-offset-6 col-sm-6">
              <Article>
                <header className="article__header">
                  <h2>About EDvice</h2>
                </header>
                <section className="article__section">
                  <p>
                    EDvice Pty Ltd (EDvice) is an education advisory firm that uses research,
                    technology, and consulting to support the education and training sector within
                    Australia.
                  </p>
                </section>
                <footer className="article__footer">
                  <a
                    className="button --red --basic"
                    href="/exams"
                    // target="_blank"
                  >
                    Learn more about EDvice
                  </a>
                </footer>
              </Article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
TakeALook.defaultProps = {};
TakeALook.propTypes = {};
export default TakeALook;
