import fetch from 'isomorphic-unfetch';

const ReactMarkdown = require('react-markdown');

class Content {
  static async get(context, collection, fields, filters) {
    let url = '';
    if (context.req) {
      url = process.env.APP_URL;
    }
    const content = await fetch(
      `${url}/api/content/?path=${collection}${fields ? `&fields=${fields}` : ''}${
        filters ? `&filters=${filters}` : ''
      }`
    ).catch(() => {
      return false;
    });
    try {
      const data = await content.json();
      this.data = data;
      return data;
    } catch (e) {
      return false;
    }
  }
}
export const Data = ReactMarkdown;
export default Content;
