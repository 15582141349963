/* eslint-disable global-require */
import React from 'react';
import Head from 'next/head';
import Content from '../../Services/Content';
import Button from '../../Components/Button';
import Article from '../../Components/Article';
import FeaturedContentTwo from '../../Components/FeaturedContentTwo';
import Hero from '../../Components/Hero';
import Navbar from '../../Components/Navbar';
import TakeALook from '../../Components/TakeALook';
import WantToLearnMore from '../../Components/WantToLearnMore';
import Footer from '../../Components/Footer';
import Information from '../../Components/Information';

class Default extends React.Component {
  static async getInitialProps(context) {
    /* Example server rendered call */
    const api = await Content.get(context, '', '*.*.*');
    return { api: api.data };
  }

  render() {
    return (
      <React.Fragment>
        <Head>
          <title key="title">Edvice</title>
          <meta key="og:title" property="og:title" content="Edvice" />
          <meta key="description" name="description" content="Edvice" />
          <meta key="og:description" property="og:description" content="Edvice" />
        </Head>

        <section>
          {/* Navbar */}
          <Navbar>Navbar</Navbar>
          {/* <Chatbot /> */}

          {/* Hero */}
          <Hero>
            <Article>
              <header className="article__header">
                <h1>
                  <span>
                    <strong>Simplify</strong>
                    &nbsp;your English language testing
                  </span>
                </h1>
              </header>
              <section className="article__section">
                <p>Register your Linguaskill test with EDvice today!</p>
              </section>
              <footer className="article__footer">
                <Button
                  as="link"
                  color="red"
                  basic
                  onClick={() => 'exams'}
                  style={{ marginRight: 20, marginTop: 10, marginLeft: 20 }}
                >
                  Discover More
                </Button>
                <Button
                  as="link"
                  color="red"
                  style={{ marginRight: 20, marginTop: 10, marginLeft: 20 }}
                  onClick={() => 'register'}
                >
                  Register now
                </Button>
              </footer>
            </Article>
          </Hero>

          {/* What we do best */}
          {/* <WhatWeDoBest /> */}

          {/* Take a Look */}
          <TakeALook />

          {/* eslint-disable-next-line global-require */}
          <FeaturedContentTwo color="red" image={require('./photo1.jpg')}>
            <Article>
              <header className="article__header" id="about">
                <h2>Proudly Indigenious</h2>
              </header>
              <section className="article__section">
                <p>
                  EDvice is a proud indigenous company Official Supply Nation registered reseller of
                  Linguaskill to Government, corporates and not-for-profits that have committed to
                  the Indigenous Procurement Policy.
                </p>
              </section>
              <footer className="article__footer">
                <a className="button --red --basic" href="/about">
                  Learn more
                </a>
              </footer>
            </Article>
          </FeaturedContentTwo>

          {/* Reverse Content */}
          {/* eslint-disable-next-line global-require */}
          <FeaturedContentTwo right sponsor color="red" image={require('./photo2.png')}>
            <Article>
              <header className="article__header" id="exams">
                <h2>Certified Partner</h2>
              </header>
              <section className="article__section">
                <p>
                  EDvice is a 49% owned subsidiary of iCollege Ltd (ASX: ICT), which also owns 100%
                  of TestEd English.
                </p>
              </section>
              <footer className="article__footer">
                <a className="button --red --basic" href="/about">
                  Learn more
                </a>
              </footer>
            </Article>
          </FeaturedContentTwo>

          {/* feature content */}
          {/* eslint-disable-next-line global-require */}
          <FeaturedContentTwo color="red" image={require('./photo3.jpg')}>
            <Article>
              <header className="article__header">
                <h2>Strong community ties</h2>
              </header>
              <section className="article__section">
                <p>
                  EDvice prides itself on its genuine links to remote indigenous communities
                  throughout Australia, in particular in far north Queensland and the Torres Strait
                  Islands.
                </p>
              </section>
              <footer className="article__footer">
                <Button as="link" color="red" basic onClick={() => '#intergrations'}>
                  Learn More
                </Button>
              </footer>
            </Article>
          </FeaturedContentTwo>

          {/* Reverse Content */}
          {/* eslint-disable-next-line global-require */}
          <FeaturedContentTwo right color="red" image={require('./photo4.png')}>
            <Article>
              <header className="article__header">
                <h2>About Linguaskill</h2>
              </header>
              <section className="article__section">
                <p>
                  Linguaskill is a quick and convenient online test to help organisations check the
                  English levels of individuals and groups of candidates. It tests all four language
                  skills: speaking, writing, reading and listening.
                </p>
                <p>
                  It provides accurate results within 48 hours, and can be easily administered and
                  invigilated at your own venue at a time that suits you. Results are aligned to the
                  Common European Framework of Reference (CEFR), the international standard for
                  describing language ability.
                </p>
              </section>
              <footer className="article__footer">
                <a className="button --red --basic" href="/about">
                  Learn more
                </a>
              </footer>
            </Article>
          </FeaturedContentTwo>

          <Information />

          {/* Quote */}
          <WantToLearnMore />

          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
export default Default;
